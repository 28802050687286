import React from 'react';
import { graphql } from 'gatsby';

import * as Core from 'components/core';
import Layout from 'components/layout/Base';

import Heading from './Heading';

interface Props {
  data: {
    generalDunford: Core.ImageAttributes;
    cspan: Core.ImageAttributes;
    anniversary: Core.ImageAttributes;
    bigStick: Core.ImageAttributes;
    petraeus: Core.ImageAttributes;
    poland: Core.ImageAttributes;
    poland2: Core.ImageAttributes;
  };
}

const FeatureContent: React.FC<Props> = (props) => {
  const {
    generalDunford,
    cspan,
    anniversary,
    bigStick,
    petraeus,
    poland,
    poland2
  } = props.data;

  return (
    <Layout title="Lasting Legacy">
      <Heading />

      <Core.ContentWrapper>
        <Core.ContentSection
          marginalia={<Core.LeadIn>By Dale Keiger</Core.LeadIn>}
        >
          <p>
            <em>
              Eliot Cohen, who steps down as dean on June 30, has made an
              indelible impact on the school he has served for more than 30
              years.
            </em>
          </p>

          <br />

          <p>
            There is a phrase attached to the United States military, whether
            professional soldiers ever use it: “boots on the ground.” It has
            many applications, one of which is to convey a sense that theory,
            strategy, abstraction, scholarship, history, philosophy, and opinion
            all play important roles in politics, international relations, and
            conflict, but in the end, one must heed the reality of what happens
            when actual people do actual things. When boots meet ground.
          </p>
          <p>
            Eliot A. Cohen has spent more than 30 years at Johns Hopkins SAIS,
            beginning as a professor and ending as dean. In that time, he has
            been a scholar, historian, commentator, adviser, teacher, author,
            and administrator. Positing a single element that runs through such
            varied work requires a measure of glibness, but here goes: Common to
            all of Cohen’s work has been fascination with and respect for boots
            on the ground.
          </p>
        </Core.ContentSection>

        <Core.ContentSection
          marginalia={
            <>
              <Core.FullWidthImage
                image={cspan}
                alt="CSPAN"
                caption="Eliot Cohen interviewed on Cspan for his article in the Washington Post, “A Hawk Questions Himself as His Son Goes to War.” July 2005."
              />
              <Core.FullWidthImage
                image={anniversary}
                alt="75th Anniversary"
                caption="75th Anniversary Dean's Address on October 24, 2019."
              />
            </>
          }
        >
          <Core.FullWidthImage
            image={generalDunford}
            alt="General Dunford"
            caption="Dean Cohen with Chairman of the Joint Chiefs of Staff, General Joseph F. Dunford, Jr."
          />

          <p>
            He steps away from the deanship and the school he has done much to
            shape on June 30. In his three decades at SAIS, he created the
            Strategic Studies program and founded the Philip Merrill Center for
            Strategic Studies, and wrote several books including{' '}
            <em>
              The Big Stick: The Limits of Soft Power and the Necessity of
              Military Force
            </em>{' '}
            (2017) and{' '}
            <em>
              Supreme Command: Soldiers, Statesmen and Leadership in Wartime
            </em>{' '}
            (2012). From 1991 to 1993 he directed and edited the official study
            of air power in the first Iraq war,{' '}
            <em>The Gulf War Air Power Survey</em>. He wrote a slew of articles
            and op-eds for newspapers and magazines. For nearly two years
            starting in April 2007, he worked as counselor of the State
            Department, a role in which he advised Secretary of State
            Condoleezza Rice on matters involving Iraq, Afghanistan, Iran,
            Pakistan, and Russia. He signed on to the 2012 presidential campaign
            of Mitt Romney as a special adviser on foreign and defense policy.
            And he guided SAIS as dean after Vali Nasr stepped down in 2019.
          </p>
        </Core.ContentSection>

        <Core.ContentSection
          marginalia={
            <Core.Blockquote
              author="Eliot Cohen"
              authorTitle=", dean"
              color="beige"
            >
              Ideas are essential, but they’re kind of like the yeast to the
              bread. The vast bulk of what you do is implementation, and if
              you’re not good on implementation, nothing else matters.
            </Core.Blockquote>
          }
        >
          <Core.FullWidthImage
            image={bigStick}
            alt="Big Stick"
            caption="The Big Stick book signing with students, faculty, staff, alumni, and donors."
          />
          <p>
            “I am very grateful to the school for the opportunities that it gave
            me at quite a young age,” Cohen says. “I was given complete freedom
            to build the kind of program that I wanted to build, to make it the
            biggest in the school and I believe the best in the country. I was
            able to serve in government at high levels. I was able to pursue
            scholarship in different fields without feeling disciplinary
            constraints and be able to take my place in the arena, as Teddy
            Roosevelt would have put it. Those are, for me, the real
            highlights.”
          </p>
          <p>
            His stint at the State Department reinforced the importance for him
            of on-the-ground reality. “I underestimated how much more important
            implementation is [than conception],” he says. “I mean, ideas are
            essential, but they’re kind of like the yeast to the bread. The vast
            bulk of what you do is implementation, and if you’re not good on
            implementation, nothing else matters.”
          </p>

          <Core.FullWidthImage
            image={petraeus}
            alt="Petraeus"
            caption="2019 Charles I. Rostov Lecture on International Affairs with General David H. Petraeus and Dean Eliot A. Cohen (October 7, 2019)"
          />

          <p>
            Cohen points to what SAIS implemented in his tenure as dean,
            streamlining the school’s structure and strengthening its
            integration with the rest of Johns Hopkins University. “We
            eliminated the structural deficit. We launched a number of new
            degree programs. We launched online education in a way we never had
            before — we had zero online courses and we now have 27. We did the
            COVID-19 transition. We beat our development targets by 25 percent
            and began tackling diversity in a way that the school had never done
            before.”
          </p>
          <p>
            He plans to continue teaching at Johns Hopkins and has two books in
            progress. The first will examine William Shakespeare “on getting,
            using, and losing power.” He says, “Shakespeare wasn’t particularly
            interested in ideology, wasn’t interested in the kind of mass forces
            underlying politics. What interested him in politics was the study
            of character, and there is no better student of character out there
            than Shakespeare.”
          </p>
          <p>
            Theodore Roosevelt will be the center of the second book. “Teddy
            Roosevelt was our most international president,” Cohen says. “He was
            fluent in French and German, actually reading books in those
            languages. He had traveled to Europe and the Middle East by the time
            he was 10, and throughout his whole career he’s thinking about the
            place of the United States to the world. And he’s of course a man of
            fascinating contradictions. We think of him as being bellicose, but
            he also won the Nobel Peace Prize. He’s a larger-than-life figure.”
          </p>
        </Core.ContentSection>

        <Core.ContentSection
          marginalia={
            <>
              <Core.FullWidthImage
                image={poland2}
                alt="Poland Staff Ride"
                caption="International Staff Ride"
              />
              <Core.Blockquote
                author="Eliot Cohen"
                authorTitle=", dean"
                color="beige"
              >
                I think SAIS can have a very bright future. Our challenge is,
                how do we define ourselves as a professional school of
                international affairs, particularly in an era that is very
                different from the one in which we were founded in 76 years ago?
              </Core.Blockquote>
            </>
          }
        >
          <Core.FullWidthImage
            image={poland}
            alt="Poland Staff Ride"
            caption="Poland International Staff Ride"
          />

          <p>
            Looking ahead for the school, Cohen says, “I think SAIS can have a
            very bright future. Our challenge is, how do we define ourselves as
            a professional school of international affairs, particularly in an
            era that is very different from the one in which we were founded in
            76 years ago?
          </p>
          <p>
            “Whether you look at the pandemic or climate change or cyberwarfare,
            the distinction between domestic and international is a lot less
            sharp than it used to be. Like any professional school, our job is
            to bring together scholarship and the world of practice, and that’s
            a struggle that requires a lot of focus and effort. It doesn’t
            happen automatically.”
          </p>
        </Core.ContentSection>
        <Core.Share />
      </Core.ContentWrapper>
    </Layout>
  );
};

export const query = graphql`
  query($directory: String!) {
    generalDunford: file(
      relativePath: { regex: $directory }
      name: { eq: "General-Dunford-2016" }
    ) {
      ...FullWidthImage
    }
    cspan: file(
      relativePath: { regex: $directory }
      name: { eq: "Cohen_CSPAN" }
    ) {
      ...MarginaliaImage
    }
    anniversary: file(
      relativePath: { regex: $directory }
      name: { eq: "20191024_SAIS_75th_1211" }
    ) {
      ...MarginaliaImage
    }
    bigStick: file(
      relativePath: { regex: $directory }
      name: { eq: "The-Big-Stick-2017_2" }
    ) {
      ...FullWidthImage
    }

    petraeus: file(
      relativePath: { regex: $directory }
      name: { eq: "Petraeus_6_Oct_7_2019" }
    ) {
      ...FullWidthImage
    }

    poland: file(
      relativePath: { regex: $directory }
      name: { eq: "Poland_International_Staff_Ride_2016_2" }
    ) {
      ...FullWidthImage
    }

    poland2: file(
      relativePath: { regex: $directory }
      name: { eq: "Poland_International_Staff_Ride_2" }
    ) {
      ...MarginaliaImage
    }
  }
`;

export default FeatureContent;
