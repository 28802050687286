import './Heading.scss';

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import * as Core from 'components/core';

const Heading: React.FC = () => {
  const { mastheadImage } = useStaticQuery<{
    mastheadImage: Core.ImageAttributes;
  }>(graphql`
    {
      mastheadImage: file(
        relativePath: {
          regex: "/issues/spring-2021/features/lasting-legacy/images/"
        }
        name: { eq: "cohen_heading" }
      ) {
        ...FullWidthImage
      }
    }
  `);

  return (
    <Core.Masthead color="light" className="LastingLegacyHeading">
      <Img
        fluid={mastheadImage.sizes.fluid}
        alt=""
        className="LastingLegacyHeading-image"
      />

      <div className="LastingLegacyHeading-wrapper">
        <div className="LastingLegacyHeading-label">
          <Core.DepartmentLabel>Feature</Core.DepartmentLabel>
        </div>
        <h1 className="LastingLegacyHeading-title">
          Lasting
          <br />
          Legacy
        </h1>
      </div>
    </Core.Masthead>
  );
};

export default Heading;
